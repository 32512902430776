import { Poppins, Quicksand } from "next/font/google";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
const quicksand = Quicksand({ weight: "400", subsets: ["latin"] });
const popins =Poppins({weight:"400",subsets:['latin']})
const extraPopins =Poppins({weight:"500",subsets:['latin']})
export default function NewCourseCard({CourseListdata}) {
  return (
    <>
    <section className="container">

    <div  className={`df fw mdf mfjc mfac gap10 pdl20 mpdl0 pdt30 pdb30 mpdt10`}>
            {CourseListdata.map((items,index) => {
              return (
        <div className="card-box" key={index}>
          <div className="pos-r tac">
            <div className="pdt16">
              <Link href={`/training/${items.path}-training`}>
                <Image
                  className="img-radius mdn"
                  src={`/course-img/bg-img/${items.path}.webp`}
                  quality={100}
                  width={"270"}
                  height={"196"}
                  alt={`${items.courseName}-Iamge`}
                  loading="lazy"
                />
              </Link>
              <Link href={`/training/${items.path}-training`}>
                <Image
                  className="img-radius dn mdb"
                  src={`/course-img/bg-img/${items.path}.webp`}
                  quality={100}
                  width={"274"}
                  height={"180"}
                  alt={`${items.courseName}-Iamge`}
                  loading="lazy"
                />
              </Link>
            </div>
            <div className="">
            <Link href={`/training/${items.path}-training`}>  <button className={` btn-box cursor fs-16 cw ${extraPopins.className}`}>Know More</button> </Link>
            </div>
           
          </div>

          <div className="df fjsa cursor mt16">
              <Link href={`/training/${items.path}-training`}>
                <p className={`fs-16  ${extraPopins.className}`}>{items.courseName}</p>
              </Link>
              <MdKeyboardArrowRight size={20} />

          </div>
        </div>
    )
})}
      </div>
      </section>
      <style jsx>
        {`
          .card-box {
            width: 295px;
            height: 286px;
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0px 4px 10px 0px #0000004D;
            transition: all 0.4s ease-in-out;
            

          }
            .btn-box {
             position: absolute;
              padding: 10px;
              border-radius: 10px;
              background-color:#FFA900;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border: none;
              opacity: 0;
              transition: all 0.4s ease-in-out;
               
            }
              .card-box:hover .btn-box{
                opacity: 1;
              }
        `}
      </style>
    </>
  );
}
