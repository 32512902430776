import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image';
import Link from 'next/link';
import React, { Component } from 'react'
const quickSand = Quicksand({ weight: "500", subsets: ["latin"] });
const popins = Poppins({ weight: "600", subsets: ["latin"] });
export default class MainSection extends Component {
  render() {
    return (
        <>
      <section className=''>
        <div className='home-bg'>
      <div className='container'>
      <div className=''>
        <div className='pdt100 ml20 mml15 mpdt63'>
        <p className={`gurantee-fs tac  ${quickSand.className}`}>100% Satisfaction Guarantee</p>
        <div className=" mt55 mmt16">
          <h4 className={`fs-45 mfs-16  ${quickSand.className}`}>
            Your partner in<span className="upgr-bg pd5 mpd2">upgrading</span>
          </h4>
          <h4 className={`fs-45 mfs-16  ${quickSand.className}`}>Your skills and knowledge</h4>
          <div className="df gap10 mt55 mmt25 ">
          <Link href={"/course"}>
          <div className="course-btn">            
              <p className={`fs-14  mfs-10  cw ${popins.className}`}>
                Our Courses
              </p>            
          </div></Link>
          <Link href={"/contact"}>
          <div className="cntct-btn">         
              <p className={`fs-14  mfs-10 hvz ${popins.className}`}>
                Contact Us
              </p>           
          </div> </Link>
        </div>
        </div>     
        </div>
        </div>    
<section>
<div className="rect-box df fjc fjsa fac mt110 mmt40">
          <div className="df fdc fac ">
            <div className="mdn">
              <Image
                src={"/home/course.webp"}
                alt="Course-Icon"
                width={"100"}
                height={"100"}
                loading="lazy" 
              />
            </div>
            <div className="dn mdb">
              <Image
                src={"/home/course.webp"}
                alt="Course-Icon"
                width={"30"}
                height={"30"}
                loading="lazy"
              />
            </div>
            <h6 className={`fs-19 cw mfs-9 ${popins.className}`}>
              30 + Courses
            </h6>
          </div>
          <div className="df fdc fac">
            <div className="mdn">
              <Image
                src={"/home/instructer.webp"}
                alt="instructer-Icon"
                width={"100"}
                height={"100"}
                loading="lazy"
              />
            </div>
            <div className="dn mdb">
              <Image
                src={"/home/instructer.webp"}
                alt="instructer-Icon"
                width={"30"}
                height={"30"}
                loading="lazy"
              />
            </div>
            <h6 className={`fs-19 cw mfs-9 ${popins.className}`}>
              Expert Instructors
            </h6>
          </div>
          <div className="df fdc fac">
            <div className="mdn">
              <Image
                src={"/home/job-assistance.webp"}
                alt="Job-assistance-Icon"
                width={"100"}
                height={"100"}
                loading="lazy"
              />
            </div>
            <div className="dn mdb">
              <Image
                src={"/home/job-assistance.webp"}
                alt="Job-assistance-Icon"
                width={"30"}
                height={"30"}
                loading="lazy"
              />
            </div>
            <h6 className={`fs-19 cw mfs-9 ${popins.className}`}>
              100% Job Assistance
            </h6>
          </div>
        </div>
</section>
      </div>
      </div>      
      </section>
      <style jsx>
        {`
        .home-bg{
            background-image: url('/home/home-bg.webp');
            width:100%;
            height: 700px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-position: cover;
            background-size: 100% 100%;
        }
        .gurantee-fs {
            background-color: #fdcf74;
            width: 240px;
            border-radius: 4px;
            height: 30px;
            line-height: 17.5px;
            font-size: 14px;
            letter-spacing: 1.25px;
            padding-top: 7px;
          }
          .upgr-bg {
            background-image: url("/home/vector-upgr.webp");
            display: inline-block;
            width: 241px;
            height: 70px;
            color: #ffffff;
            text-align: center;
            background-repeat: no-repeat;
          }
         .course-btn{
          background-color: #FFA900;
          padding:11px 32px 11px 32px;
          border-radius: 4px;
          cursor: pointer;
         }
         .cntct-btn{
          padding:11px 32px 11px 32px;
          border-radius: 4px;
          border: 1px solid #FFA900;
          cursor: pointer;
          transition: all 0.4s linear;
         }
         .cntct-btn:hover {
          background-color: #fdbb39;
          box-shadow: 0px 0px 6px 9px #fdbb3940;
        }      
        .course-btn:hover {
          box-shadow: 0px 0px 6px 9px #fdbb3940;
          background-color: #fdbb39;
        }
        .cntct-btn:hover .hvz{
          color: #ffffff;
        }
         .rect-box {
          width: 100%;
          height: 165px;
          background-color: #ffa900;
          text-align: center;
        }
          @media only screen and (max-width: 1023px) {
            /* Adjust styles for smaller screens */
            .home-bg {
                width:100%;
                height: 285px;
            }
            .upgr-bg {
              background-image: url("/home/mb-vector-upgr.webp");
              display: inline-block;
              width: 90px;
              height: 18.81px;
              color: #ffffff;
              text-align: center;
              background-repeat: no-repeat;
            }
            .gurantee-fs {
              background-color: #fdcf74;
              width: 170.39px;
              border-radius: 1.19px;
              height: 8.92px;
              line-height: 5.2px;
              font-size: 10px;
              letter-spacing: 1.25px;  
            }
            .course-btn{
              background-color: #FFA900;
              padding: 7px 3px 7px 3px;
              border-radius: 1.19px;
              width: 74.02px;
              text-align: center;
             }
             
             .cntct-btn{
              padding:3.27px 9.51px 3.27px 9.51px;
              border-radius: 1.19px;
              border: 0.3px solid #FFA900;
              cursor: pointer;
              width: 74.02px;
              padding: 7px 3px 7px 3px;
              text-align: center;            
             }
             .rect-box {
              width: 100%;
              height: 50.04px;
              background-color: #ffa900;
              text-align: center;
              
            }
          }
        
        `}
      </style>
      </>
    )
  }
}
