import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react'
import { PiPhoneCallBold } from "react-icons/pi";
const popins = Poppins({weight:"400",subsets:['latin'],})
const popinsfw = Poppins({weight:"500",subsets:['latin'],})
const extrapopins = Poppins({weight:"600", subsets:['latin'],});
const quickSand = Quicksand({weight:"400",subsets:['latin'],})
export default function HeroSection() {
  return (
    <>
    <section className='home-bg pdtb20'>
       <div className='container'>
         <div className='df fac fjc tfdc'>
            {/* Content */}
            <div className='w50p mwap'>
            <h4 className={`fs-45 mfs-24  ${extrapopins.className}`}>Learn Skills From Our Top  Instructors</h4>
            <p className={`fs-24 mfs-19 mt20 mmt10 ${popins.className}`}>Learnsoft IT Training Institute provides industry-relevant courses with expert instructors, offering practical training to help students advance in IT careers</p>
             <div className='mt32 df gap20 fw mfjc'>
             <Link href={"/course"}> <button className={`explore-btn pdtb10 cursor fs-19 cw mfs-16 popinsfw`}>Explore Courses</button></Link> 
             <div className='df gap5 fac'>
             <PiPhoneCallBold size={31} color='#FDBB39'/>
             <div className=''>
                <p className={`fs-12 ${popins.className}`}>Have any Question?</p>
                <p className={`fs-16 ${popins.className}`}>+91 7825 88 88 99</p>
             </div>
             </div>
             </div>
            </div>
            {/* Images */}
            <div className='mpdt20'>
               <Image className='mdn' src={"/home-v2-images/home-vector.webp"} width={574} height={574} alt='Home-Vector' loading='lazy'/>     
               <Image className='dn mdb ' layout='responsive' src={"/home-v2-images/home-vector.webp"} width={574} height={574} alt='Home-Vector' loading='lazy'/>     

             </div>
         </div>
       </div>
    </section>
    <style jsx>
        {`
         .home-bg{
            background-image: url('/home-v2-images/home-bg.webp');
            width:100%;
            height: auto;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-position: cover;
            background-size: 100% 100%;
        }
            .explore-btn{
            background-color: #FDBB39;
            border-radius: 10px;
            width: 295px;
            border: none;
            }
                      @media only screen and (max-width: 1250px){
              .tfdc{
              display: flex;
              flex-direction: column;
              align-items: center;
              }
        }
        `}
    </style>
    </>
  )
}
