import { Poppins, Quicksand } from 'next/font/google';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react'
const popins = Poppins({weight:"400",subsets:['latin']})
const popinsfw = Poppins({weight:"500",subsets:['latin']})
const extrapopins = Poppins({weight:"600", subsets:['latin']});
const quickSand = Quicksand({weight:"400",subsets:['latin']})
export default function HomeBlogCards() {
  const BlogSubCard = [
    {
      img:"/new-blog-cards/aws-certification.webp",
      blogHeading:"AWS Certification",
      cnt:"Passing one or more tests that test your knowledge of Azure services ...",
      publishDate:"4 Oct 2024",
      path:"/blog/aws-certification"
    },
    {
      img:"/blog-imgs/new-blog-cards/sap-basis.webp",
      blogHeading:"SAP BASIS",
      cnt:"In today's complex and dynamic business environment, enterprise resource ...",
      publishDate:"3 Oct 2024",
       path:"/blog/sap-basis"
    },
  ]
  return (
    <>
    <section className='container'>
      <div className='mt70 mmt20 mb19'>
        <div className='tac'>
        <h4 className={`fs-33 mfs-24 mtac ${extrapopins.className}`}>Latest News <span className='ylw-clr'> Blogs</span></h4>
        <p className={`fs-16 mt32 mmt16 ${popinsfw.className}`}>Join our latest blogs at Learnsoft to explore the newest insights in IT training, career tips, and industry updates</p>
        </div>
        <div  className='mt32 mmt20 df gap20 tfdc'>
          <Link href={"/blog/azure-certification"}>
            <div className='blog-main-card pd10 '>
                 <div className='tac'>
                 <Image className='mdn' src={"/new-blog-cards/azure-certification.webp"} width={590} height={428} alt='Azure-Certifications' loading='lazy'/>
                 <Image className='dn mdb' src={"/new-blog-cards/azure-certification.webp"} width={250} height={181} alt='Azure-Certifications' loading='lazy'/>
                 </div>
                 <div className=''>
                    <h6 className={`fs-24 pdt10 tac ${popins.className}`}>Azure Certification</h6>
                    <p className={`fs-16 pdt10 ${popins.className}`}>Passing one or more tests that test your knowledge of Azure services ...</p>
                    <p className={`fs-14 pdt15 ${popinsfw}`}><span><Image src={"/new-blog-cards/date-icon.svg"} width={24} height={24} alt='Date-Icon' loading='lazy'/> </span> 01 Oct 2024 </p>
                 </div>
            </div>
            </Link>
            <div>
            {BlogSubCard.map((items,index)=>{
              return(
                <Link href={`${items.path}`}>
            <div className='blog-sub-card  pd10 mt20' key={index}>
             
              <div className='df gap8 blog-mdf'>
                <div className={``}>
                <Image src={items.img} width={250} height={181} alt={`${items.blogHeading}-Imgs`} loading='lazy'/>
                </div>
                <div className={``}>
                <h6 className={`fs-24 pdt10 tac ${popins.className}`}>{items.blogHeading}</h6>
                    <p className={`fs-16 pdt10 ${popins.className}`}>{items.cnt}</p>
                    <p className={`fs-14 pdt15 ${popinsfw}`}><span><Image src={"/new-blog-cards/date-icon.svg"} width={24} height={24} alt='Date-Icon' loading='lazy'/> </span>{items.publishDate}</p>
                </div>
              </div>
            </div>
            </Link>
            )})}
            </div>
           
        </div>
        </div>
    </section>
    <style jsx>
     {`
     .blog-main-card{
      border: 1px solid #000;
      width: 610px;
      border-radius: 10px;
      box-shadow: 0px 4px 16px 2px #00000040;
     }
      .blog-sub-card{
      width: 608px;
      border: 1px solid #000;
      border-radius: 10px;
      box-shadow: 0px 4px 16px 2px #00000040;
      height: 201px;
      
      }
      @media only screen and (max-width: 650px){
    .blog-main-card{
      border: 1px solid #000;
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 4px 16px 2px #00000040;

     }
           .blog-main-card{
      border: 1px solid #000;
      width: 260px;
      border-radius: 10px;
      box-shadow: 0px 4px 16px 2px #00000040;
     }
      .blog-sub-card{
      width: 260px;
      border: 1px solid #000;
      border-radius: 10px;
      box-shadow: 0px 4px 16px 2px #00000040;
      height: auto;
      
      }
      .blog-mdf{
      display: flex;
              flex-direction: column;
              align-items: center;
      }
      }
              @media only screen and (max-width: 1250px){
              .tfdc{
              display: flex;
              flex-direction: column;
              align-items: center;
              }
        }
     
     `}
    </style>
    </>
  )
}
